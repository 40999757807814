import React from 'react'

const ComingSoon = () => {
  return (
    <div style={{textAlign: "center"}} className="commingsoon">
    <h1>
    <h1 style={{color:"red",fontSize:"50px"}}>Contests are comingSoon </h1>
    </h1>
  </div>
  )
}

export default ComingSoon