// const Url = "https://client.securefx.net";
// const Url = "https://referral.securefx.net";
const Url=".";

var fullURL = window.location.href;
var baseURL = fullURL.split("/").slice(0, 3).join("/");

// const IsApprove = {
//   is_app: 1,
//   auth: "8732c6-7a3e7e-d8f1ac"  ,
//   user_id: "987",
//   is_crm: 1,
// };
const IsApprove = "";
export { Url, IsApprove,baseURL };
